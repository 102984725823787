import tippy from '../src';
import {render} from '../template';

tippy.setDefaultProps({render});

export {default, hideAll} from '../src';
export {default as createSingleton} from '../addons/createSingleton';
export {default as delegate} from '../addons/delegate';
export {default as animateFill} from '../plugins/animateFill';
export {default as followCursor} from '../plugins/followCursor';
export {default as inlinePositioning} from '../plugins/inlinePositioning';
export {default as sticky} from '../plugins/sticky';
export {ROUND_ARROW as roundArrow} from '../constants';
