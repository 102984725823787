import Splide from "@splidejs/splide"
import Choices from "choices.js"
import Cleave from "cleave.js"
import 'cleave.js/dist/addons/cleave-phone.ru'
import "fslightbox"
import Tippy from "tippy.js"
import InputContollerGroup from "./_InputControllerGroup"
import Popup from "./_Popup"
import Quantity from "./_Quantity"
import Spoiler from "./_Spoiler"
import ScrollToggler from "./_ScrollToggler"
import FileInput from "./_FileInput"
import { bindSplideArrows, ifClickInside, toggleNoscrollBody } from "./_helpers"

import { initLanding2InnerMenu, initLandind2FAQ } from "./_landing2"

window.initMap = initMap
document.addEventListener('DOMContentLoaded', function () {
	initPopups()
	initSelects()
	initTippy()
	observeSections()
	initTabs()
	initPhoneMask()
	// initQuantity()
	initSpoilers()
	// initInputControllerGroups()
	initScrollPreventingSlider()
	initCustomFileInputs()
	initSteps()
	initPartnersSlider()
	syncInputs()
	initRequestCheckerButtons()
	initHeaderSearch()
	initSearchInput()
	// requestFormSubmitHandler()
	// cardsRequestFormSubmitHandler()
	formsSubmit()
	initRequestDescriptionFill()
	casesLoadMoreBtnHandler()
	initTextAutoprint()
	inputChangeOnFormReset()
	initFlipCards()
	initSamplesSlider()
	initAboutSlider()
	initMainDiscussForm()
	initSupportComplexSlider()
	initIntegrationCasesSlider()
	initIntegrationTeamSlider()
	initIntegrationReviewsSlider()

	initLanding2InnerMenu()
	initLandind2FAQ()
	initAdvantagesSlider()
	initExpertiseSlider()
	initImageSlider()
	initCasesSlider()
})

window.addEventListener("load", () => {
	headerHeightToCSS()
})

function initIntegrationReviewsSlider() {
	document.querySelectorAll(".integration__reviews").forEach(container => {
		const slider = container.querySelector(".splide")
		const controls = container.querySelector(".splide__arrows")

		let splide = new Splide(slider, {
			perMove: 1,
			perPage: 3,
			gap: 10,
			arrows: false,
			pagination: false,
			breakpoints: {
				1440: {
					perPage: 1,
					autoWidth: true,
					padding: { left: 15, right: 15 }
				}
			}
		}).mount()


		bindSplideArrows(splide, controls)
	})
}

function initIntegrationTeamSlider() {
	document.querySelectorAll(".integration__team").forEach(container => {
		const slider = container.querySelector(".splide")
		const controls = container.querySelector(".splide__arrows")

		let splide = new Splide(slider, {
			perMove: 1,
			perPage: 2,
			gap: 10,
			arrows: false,
			pagination: false,
			breakpoints: {
				1440: {
					perPage: 1,
					autoWidth: true,
					padding: { left: 15, right: 15 }
				},
				768: {
					destroy: true
				}
			}
		}).mount()


		bindSplideArrows(splide, controls)
	})
}

function initIntegrationCasesSlider() {
	document.querySelectorAll(".integration__cases").forEach(container => {
		const slider = container.querySelector(".splide")
		const controls = container.querySelector(".splide__arrows")

		let splide = new Splide(slider, {
			destroy: true,
			arrows: false,
			pagination: false,
			breakpoints: {
				1440: {
					destroy: false,
					gap: 10,
					perPage: 1,
					autoWidth: true,
					pagination: false,
					padding: { left: 15, right: 15 }
				},
				768: {
					destroy: true
				}
			}
		}).mount()

		bindSplideArrows(splide, controls)
	})
}

function initSupportComplexSlider() {
	document.querySelectorAll(".support__complex__grid .splide").forEach(slider => {
		new Splide(slider, {
			destroy: true,
			breakpoints: {
				768: {
					destroy: false,
					fixedWidth: 249,
					gap: 10,
					pagination: false,
					padding: { left: 15, right: 15 }
				}
			}
		}).mount()
	})
}

function initMainDiscussForm() {
	document.querySelectorAll(".main__discuss__form").forEach(form => {
		form.querySelectorAll(".main__discuss__files__item").forEach(files => {
			files.querySelectorAll("input[type=file]").forEach(input => {
				input.addEventListener("change", () => {
					files.querySelector("label span").innerText = input.files[0].name
				})
			})
		})
	})
}

function initFlipCards() {
	document.querySelectorAll(".support__complex__card").forEach(card => {
		card.querySelectorAll(".support__complex__card__toggler").forEach(toggler => {
			toggler.addEventListener("click", () => {
				card.classList.toggle("is-active")
			})
		})
	})
}

function inputChangeOnFormReset() {
	document.querySelectorAll("form").forEach(form => {
		let inputs = form.querySelectorAll("input")
		form.addEventListener("reset", () => {
			inputs.forEach(input => {
				requestAnimationFrame(() => {
					input.dispatchEvent(new Event("change"))
				})
			})
		})
	})
}

function initTextAutoprint() {
	document.querySelectorAll(".text-autoprint").forEach(block => {
		let origin = block.querySelector(".text-autoprint__origin")
		let textOrigins = block.dataset.textAutoprintSet.split(";")
		let text = [origin.textContent.split(""), ...textOrigins.map((curr) => curr.split(""))]
		let showCount = 0
		let textItem = 0

		function getRandomArbitrary(min, max) {
			return Math.random() * (max - min) + min
		}

		function print() {
			setTimeout(() => {
				check()
				set()
				requestAnimationFrame(print)
			}, getRandomArbitrary(100, 300))
		}

		function check() {
			if (showCount++ == text[textItem].length) {
				showCount = 0
				if (textItem++ == text.length - 1) {
					textItem = 0
				}
			}
		}

		function set() {
			origin.textContent = text[textItem].join("")
			block.setAttribute("data-text", text[textItem].slice(0, showCount).join(""))
		}

		print()
	})
}

function makeFormTester(form) {
	let name = form.querySelector("input[data-validate=name]")
	let email = form.querySelector("input[data-validate=email]")
	let tel = form.querySelector("input[data-validate=tel]")
	let checks = form.classList.contains("cards-request__form") ? [...form.querySelectorAll(".cards-request__form__checkbox-group input")] : null
	let all = form.querySelectorAll("input")
	all.forEach(input => {
		input.removeAttribute("required")
		input.addEventListener("input", () => {
			input.classList.remove('is-invalid')
		})
	})

	function testForm() {
		all.forEach(input => {
			input.classList.remove('is-invalid')
		})

		let nameInvalid = !name?.checkValidity() || name?.value?.length == 0
		let checksInvalid = checks ? !(checks.find((check) => check.checked)) : null

		const isInvalidEmail = Boolean(!email?.value?.length || !email?.checkValidity())
		const isInvalidTel = Boolean(!tel?.value?.length || !tel?.checkValidity() || tel?.value === '+7')

		if (tel) {
			tel.classList.toggle("is-invalid", isInvalidTel)
			isInvalidTel && tel.focus()
		}
		if (email) {
			email.classList.toggle("is-invalid", isInvalidEmail)
			isInvalidEmail && email.focus()
		}
		if (name) {
			name.classList.toggle("is-invalid", nameInvalid)
			nameInvalid && name.focus()
		}
		if (checks && checks.length > 0)
			checks[0].parentNode.parentNode.classList.toggle("is-invalid", checksInvalid)

		return !(nameInvalid || isInvalidEmail || isInvalidTel || (checksInvalid ?? false))
	}

	return testForm
}

function initSearchInput() {
	let wrapper = document.querySelector(".search-banner__input")
	if (wrapper) {
		wrapper.querySelector(".search-banner__input__cleaner").addEventListener("click", () => {
			wrapper.querySelector("input").value = ""
		})
	}
}

function initAboutSlider() {
	document.querySelectorAll(".support__about__slider").forEach(container => {
		const slider = container.querySelector(".splide")
		const controls = container.querySelector(".splide__arrows")
		let ifInsideIntegration = Boolean(container.closest(".integration__about"))

		const splide = new Splide(slider, {
			perPage: 1,
			gap: 25,
			pagination: false,
			perMove: 1,
			breakpoints: {
				768: {
					pagination: ifInsideIntegration
				}
			}
		}).mount()

		bindSplideArrows(splide, controls)
	})
}

function initSamplesSlider() {
	document.querySelectorAll(".support__samples").forEach(container => {
		const slider = container.querySelector(".splide")
		const controls = container.querySelector(".splide__arrows")

		const splide = new Splide(slider, {
			perPage: 3,
			gap: 75,
			pagination: false,
			arrows: false,
			perMove: 1,
			breakpoints: {
				1024: {
					perPage: 2,
					gap: 30,
				},
				768: {
					perPage: 1,
					gap: 30,
				}
			}
		}).mount()

		bindSplideArrows(splide, controls)
	})
}

function initPartnersSlider() {
	document.querySelectorAll(".partners").forEach(container => {
		const slider = container.querySelector(".splide")
		const controls = container.querySelector(".splide__arrows")
		const isTrusters = !!slider.closest('.landing2-trusters')

		const splide = new Splide(slider, {
			perPage: 4,
			gap: 10,
			pagination: false,
			arrows: false,
			perMove: 1,
			destroy: false,
			breakpoints: {
				1024: {
					perPage: 3,
				},
				767: {
					destroy: isTrusters,
					fixedWidth: 239
				}
			}
		}).mount()

		bindSplideArrows(splide, controls)
	})
}

function initAdvantagesSlider() {
	const slider = document.querySelector('.landing2-advantages .splide')
	if (slider) {
		const splide = new Splide(slider, {
			destroy: true,
			pagination: true,
			perPage: 1,
			gap: 15,
			arrows: false,
			breakpoints: {
				767: {
					destroy: false
				}
			}
		}).mount()
	}
}

function initExpertiseSlider() {
	const slider = document.querySelector('.landing2-expertise .splide')
	const controls = document.querySelector('.landing2-expertise .splide__arrows')
	if (slider) {
		const splide = new Splide(slider, {
			pagination: false,
			perPage: 1,
			gap: 15,
			arrows: false
		}).mount()

		bindSplideArrows(splide, controls)
	}
}

function initImageSlider() {
	const slider = document.querySelector('.done-slider .splide')
	const controls = document.querySelector('.done-slider .splide__arrows')
	if (!slider)
		return

	const splide = new Splide(slider, {
		pagination: false,
		perPage: 1,
		gap: 15,
		arrows: false
	}).mount()

	bindSplideArrows(splide, controls)
}

function initCasesSlider() {
	const slider = document.querySelector('.case-cards.splide')
	if (!slider)
		return

	const splide = new Splide(slider, {
		pagination: false,
		perPage: 3,
		gap: 10,
		arrows: false,
		breakpoints: {
			1023: {
				perPage: 1
			},
			767: {
				destroy: true
			}
		}
	}).mount()
}

function initMap() {
	if (!document.querySelector("#map")) {
		return
	}

	var myMap = new ymaps.Map('map', {
		center: [55.782932, 37.715911],
		zoom: 16,
		controls: []
	}),


		myPlacemark = new ymaps.Placemark(myMap.getCenter(), {}, {
			iconLayout: 'default#image',
			iconImageHref: '/local/templates/new/images/icon/marker.svg',
			iconImageSize: [116, 141],
			iconImageOffset: [-58, -141]
		})


	myMap.geoObjects
		.add(myPlacemark)

}

function initCustomFileInputs() {
	document.querySelectorAll(".custom-file-input").forEach(input => {
		new FileInput(input)
	})
}

function initScrollPreventingSlider() {
	document.querySelectorAll(".scroll-preventing-slider ").forEach(container => {
		let type = container.dataset.sliderType
		let slider = container.querySelector('.splide')
		let opts = {
			1: {
				perPage: 1,
				arrows: false,
				gap: 10,
				pagination: false,
				trimSpace: true,
				autoplay: 'pause',
				speed: 500,
				loop: false,
				start: 1,
				drag: "free",
				snap: true,
				breakpoints: {
					768: {
						padding: 5
					}
				}
			},
			2: {
				perPage: 1,
				arrows: false,
				gap: 10,
				pagination: false,
				trimSpace: false,
				autoplay: 'pause',
				speed: 500,
				loop: false,
				start: 1,
				drag: "free",
				snap: true,
				breakpoints: {
					768: {
						perPage: 1,
						padding: 20
					}
				}
			}
		}

		let splide = new Splide(slider, opts[type]).mount()

		slider.addEventListener("wheel", (event) => {
			event.stopPropagation()
			event.preventDefault()
			requestAnimationFrame(() => {
				splide.Components.Controller.scroll(splide.Components.Move.getPosition() + -event.deltaY * 4, 0, false, () => {
					requestAnimationFrame(() => {
						splide.Components.Controller.scroll(splide.Components.Move.getPosition(), 0, true)
					})
				})
			})
		})

		new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					splide.Components.Autoplay.play()
				} else {
					splide.Components.Autoplay.pause()
					splide.go(1)
				}
			})
		}).observe(slider)
	})
}

function debounce(f, ms) {
	let isCooldown = false

	return function () {
		if (isCooldown) return

		f.apply(this, arguments)

		isCooldown = true

		setTimeout(() => isCooldown = false, ms)
	}
}

function observeSections() {
	document.querySelectorAll("section").forEach(section => {
		new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add("is-visible")
					observer.unobserve(section)
				}
			})
		}).observe(section)
	})
}

function initTippy() {
	document.querySelectorAll("[data-tippy-content]").forEach(host => {
		Tippy(host, {
			placement: "right-start",
			arrow: false
		})
	})
}

function initSelects() {
	document.querySelectorAll(".custom-input select").forEach(select => {
		new Choices(select, {
			searchEnabled: false,
			sorter: () => 0
		})
	})
}

function initQuantity() {
	document.querySelectorAll(".quantity").forEach(item => {
		new Quantity(item)
	})
}

function initInputControllerGroups() {
	InputContollerGroup.findGroups().forEach(group => {
		new InputContollerGroup(group)
	})
}

function initPhoneMask() {
	document.querySelectorAll('input[type=tel]')
		.forEach(input => {
			new Cleave(input, {
				phone: true,
				phoneRegionCode: "RU",
				delimiter: "-",
				prefix: "+7",
				noImmediatePrefix: true
			})
		})
}

function headerHeightToCSS() {
	document.querySelector(':root')
		.style.setProperty('--header-height', `${document.querySelector('header').getBoundingClientRect().height}px`)
}

function initHeaderSearch() {
	let header = document.querySelector("header")
	let toggler = header.querySelector(".header__search-toggler")
	let input = header.querySelector(".header__search-input input")
	if (!(input && toggler)) {
		return
	}
	toggler.addEventListener("click", () => {
		if (header.classList.contains("search-opened")) {
			header.classList.remove("search-opened")
			if (innerWidth < 768) {
				toggleNoscrollBody(false)
			}
		} else {
			header.classList.add("search-opened")
			input.focus()
			if (innerWidth < 768) {
				setTimeout(() => {
					toggleNoscrollBody(true)
				}, 100)
			}
		}
	})

	header.querySelector(".header__burger").addEventListener("click", () => {
		header.classList.toggle("search-opened", false)
	})
}

function initPopups() {
	window.popups = []
	document.querySelectorAll("[data-popup]").forEach(popup => {
		window.popups[popup.id] = new Popup(popup)
	})
}

function initTabs() {
	let controls = document.querySelectorAll(".tabs__controls__control input[type=radio]")
	let selectControls = document.querySelectorAll(".tabs__controls__control select")

	function updateSlave(master, slave) {
		if (master.checked) {
			toggleNoscrollBody(true)
			slave.checked = true
			toggleNoscrollBody(false)
		}
	}

	controls.forEach(master => {
		let slaves = document.querySelectorAll(`[data-radio="${master.dataset.radioTarget}"]`)
		slaves.forEach(slave => {

			updateSlave(master, slave)
			master.addEventListener("change", () => {
				updateSlave(master, slave)
			})
		})
	})

	selectControls.forEach(select => {
		controls.forEach(control => {
			function update() {
				if (control.dataset.radioTarget == select.value) {
					control.checked = true
					control.dispatchEvent(new Event("change"))
				}
			}

			update()
			select.addEventListener("change", update)
			control.addEventListener("change", () => {
				select.querySelectorAll("option").forEach(option => {
					if (option.value == control.dataset.radioTarget) {
						option.selected = true
					}
				})
			})
		})
	})
}

function initSpoilers() {
	document.querySelectorAll('.spoiler')
		.forEach(spoiler => {
			new Spoiler(spoiler)
		})
}

function syncInputs() {
	let groups = {}
	document.querySelectorAll("[data-input-sync]").forEach(input => {
		if (!(typeof groups[input.dataset.inputSync] === "object")) {
			groups[input.dataset.inputSync] = []
		}
		groups[input.dataset.inputSync].push(input)
	})
	for (const group of Object.values(groups)) {
		group.forEach(input => {
			input.addEventListener("change", function () {
				group.forEach(input => {
					input.checked = this.checked
				})
			})
		})
	}
}

function initRequestCheckerButtons() {
	document.querySelectorAll("[data-request-checked]").forEach(button => {
		let inputs = document.querySelectorAll(`.request .radio-tags input[name]`)
		let toCheck = JSON.parse(button.dataset.requestChecked)

		button.addEventListener("click", () => {
			inputs.forEach(input => {
				if (toCheck.includes(input.name)) {
					input.checked = true
				} else {
					input.checked = false
				}
			})
		})
	})
}

function initRequestDescriptionFill() {
	document.querySelectorAll("[data-request-text]").forEach(button => {
		let descInput = document.querySelector(`.request textarea[name="description"]`)
		let descText = button.dataset.requestText

		button.addEventListener("click", () => {
			if (descText) {
				descInput.innerHTML = descText
			}
		})
	})
}

function initSteps() {
	document.querySelectorAll(".steps .steps__item").forEach(item => {
		new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.isIntersecting) {
					entry.target.classList.add("steps__item--is-visible")
					observer.unobserve(item)
				}
			})
		}, {
			threshold: 1.0
		}).observe(item)
	})
}

function formsSubmit() {
	document.querySelectorAll(".request, .cards-request__form, .request_new, .techsupport-form__body").forEach(form => {
		let test = makeFormTester(form)
		function send() {
			fetch("/ajax/forms/request_form.php", {
				method: "POST",
				body: new FormData(form)
			}).then(response => {
				if (response.ok) {
					return response.json()
				} else {
					throw new Error('Ошибка запроса')
				}
			}).then(result => {
				if (result.status == 'error') {
					throw new Error('Ошибка на сервере')
				} else {
					form.reset()
					window.popups['popup_request'].closePopup()
					window.popups['popup_request__success'].openPopup()
				}
			}).catch(error => {
				console.error(error)
				window.popups['popup_request__failure'].openPopup()
			})
		}
		form.addEventListener("submit", function (event) {
			event.preventDefault()
			if (test()) {
				send()
			} else {
				console.warn("Форма невалидна")
			}
		})
	})
}

function casesLoadMoreBtnHandler() {
	let wrapper = document.querySelector(".cases-container")
	let pagination = document.querySelector(".js-cases-pagination")

	if (!(wrapper || pagination)) {
		return false
	}

	document.addEventListener("click", (event) => {
		let button = document.querySelector(".js-cases-load-more")
		if (!button) {
			return
		}
		if (ifClickInside(event, [button])) {
			event.preventDefault()
			let href = button.getAttribute("href")
			let getURL = new URL(href, window.location.href)
			getURL.searchParams.append("ajaxPage", "Y")
			fetch(getURL, {
				method: "GET",
				headers: {
					'Content-type': 'application/json; charset=utf-8'
				}
			}).then(response => {
				if (response.ok) {
					return response.json()
				} else {
					throw new Error("Произошла ошибка при выполнении запроса")
				}
			}).then(result => {
				wrapper.innerHTML += result.content
				pagination.innerHTML = result.showmore
				history.pushState(null, '', href)
			}).catch(error => {
				console.error(error)
			})
		}
	})
}