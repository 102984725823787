import Splide from "@splidejs/splide"
import { bindSplideArrows } from "./_helpers"

export { initUniversalInnerMenu, initUniversalMenuSlider, initUniversalCases, initUniversalProfitSlider, initUniversalResponsesSlider }

function initUniversalInnerMenu() {
	/** @type {Array<HTMLLIElement>} */
	const navElements = Array.from(document.querySelectorAll('.universal-inner-navigation .navUl li'))
	navElements.forEach(nav => nav.addEventListener('click', () => {
		scrollToElement(nav.dataset.target)
	}))
}

/** Прокрутка к элементу с указанным селектором
 * @param {string} selector
 */
function scrollToElement(selector) {
	const menu = document.querySelector('.universal-inner-navigation')
	const topOffset = menu ? menu.getBoundingClientRect().height + 6 : 0

	const target = document.querySelector(selector)
	if (!target)
		return

	let targetTopOffset = target.getBoundingClientRect().top
	const isTargetAnimationDone = target.classList.contains('is-visible')
	if (!isTargetAnimationDone)
		targetTopOffset -= 100

	window.scrollTo(0, targetTopOffset + window.scrollY - topOffset)
}

function initUniversalMenuSlider() {
	const sliderParent = document.querySelector('.universal-inner-navigation .splide')

	if (!sliderParent)
		return

	const splide = new Splide(sliderParent, {
		autoWidth: true,
		gap: 30,
		pagination: false,
		arrows: true,
		perMove: 1,
		destroy: true,
		breakpoints: {
			1023: {
				destroy: false,
			},
			767: {
				gap: 10
			}
		}
	}).mount()

	const controls = sliderParent.querySelector(".splide__arrows")
	bindSplideArrows(splide, controls)
}

/** Слайдер Похожих кейсов */
function initUniversalCases() {
	document.querySelectorAll(".universal-cases .splide")
		.forEach(slider =>
			new Splide(slider, {
				perMove: 1,
				perPage: 3,
				gap: 10,
				arrows: false,
				pagination: false,
				breakpoints: {
					1439: {
						perPage: 2
					},
					767: {
						perPage: 1
					}
				}
			}).mount()
		)
}

/** Слайдер блока "Польза от эксперта" */
function initUniversalProfitSlider() {
	document.querySelectorAll(".universal-profit .splide")
		.forEach(slider =>
			new Splide(slider, {
				perMove: 1,
				perPage: 3,
				gap: 10,
				arrows: false,
				pagination: false,
				breakpoints: {
					1439: {
						perPage: 2
					},
					767: {
						perPage: 1
					}
				}
			}).mount()
		)
}

/** Слайдер отзывов */
function initUniversalResponsesSlider() {
	document.querySelectorAll(".universal-responses .splide")
		.forEach(slider => {
			const controls = slider.parentNode.querySelector('.splide__arrows')
			const splide = new Splide(slider, {
				perMove: 1,
				perPage: 3,
				gap: 10,
				arrows: false,
				pagination: false,
				destroy: true,
				breakpoints: {
					1200: {
						destroy: false,
						perPage: 2
					},
					767: {
						perPage: 1
					}
				}
			}).mount()

			bindSplideArrows(splide, controls)
		})
}