class FileInput {
	constructor(wrapper) {
		this.wrapper = wrapper
		this.input = wrapper.querySelector(".custom-file-input__input")
		this.status = wrapper.querySelector(".custom-file-input__info__status")
		this.name = {
			name: wrapper.querySelector(".custom-file-input__info__name__start"),
			ext: wrapper.querySelector(".custom-file-input__info__name__end"),
		}
		this.chosenClass = "custom-file-input--chosen"
		this.parentForm = this.input.closest("form")

		this.bindEvents()
	}

	bindEvents() {
		this.input.addEventListener("change", () => {
			this.changeHandler()
		})
		this.parentForm?.addEventListener("reset", () => {
			this.changeHandler()
		})
	}

	changeHandler() {
		requestAnimationFrame(() => {
			if (this.input.files.length) {
				let file = this.input.files[0]
				let fullname = file.name.split(".")
				let ext = `.${fullname[fullname.length - 1]}`
				let name = fullname.slice(0, -1).join('.')

				this.name.name.innerHTML = name
				this.name.ext.innerHTML = ext

				this.wrapper.classList.add(this.chosenClass)
			} else {
				this.wrapper.classList.remove(this.chosenClass)
			}
		})
	}
}

export default FileInput