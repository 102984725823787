import UxSelect from "ux-select/dist/js/ux-select.es.js"

export { initLanding2InnerMenu, initLandind2FAQ }

function initLanding2InnerMenu() {
	/** @type {Array<HTMLLIElement>} */
	const navElements = Array.from(document.querySelectorAll('.landing2-inner-navigation .navUl li'))
	navElements.forEach(nav => nav.addEventListener('click', () => {
		scrollToElement(nav.dataset.target)
	}))
}

/** Прокрутка к элементу с указанным селектором
 * @param {string} selector
 */
function scrollToElement(selector) {
	const menu = document.querySelector('.landing2-inner-navigation')
	const topOffset = menu ? menu.getBoundingClientRect().height + 6 : 0

	const target = document.querySelector(selector)
	if (!target)
		return

	let targetTopOffset = target.getBoundingClientRect().top
	const isTargetAnimationDone = target.classList.contains('is-visible')
	if (!isTargetAnimationDone)
		targetTopOffset -= 100

	window.scrollTo(0, targetTopOffset + window.scrollY - topOffset)
}

function initLandind2FAQ() {
	/** @type {Array<HTMLElement>} */
	const FAQElements = Array.from(document.querySelectorAll('.landing2-faq .faq__question'))
	FAQElements.forEach(question =>
		question.querySelector('.expander')?.addEventListener('click', () => question.classList.toggle('open'))
	)
}


document.addEventListener('DOMContentLoaded', () => {
	initCustomSelect()

	window.addEventListener('scroll', setClassesForStickedMenu)
	window.addEventListener('resize', setClassesForStickedMenu)
	setClassesForStickedMenu()
})

// Кастомный селект мобильного вида внутренней навигации страницы
let uxSel = undefined
function initCustomSelect() {
	document.querySelectorAll('select.custom-select').forEach(sel => {
		uxSel = new UxSelect(sel, { placeholder: '', hideOnSelect: true })

		sel.addEventListener('change', () => {
			// Точка — значение для плейсхолдера меню
			if (sel.value != '.')
				scrollToElement(sel.value)
		})
	})
}

function setClassesForStickedMenu() {
	const menu = document.querySelector('.landing2-inner-navigation')
	if (!menu)
			return
	const menuGeometry = menu.getBoundingClientRect()
	const menuTopPosition = menuGeometry.top

	// Тут может оказаться не 0, а высота главного меню, если оно тоже прилипает
	menu.classList.toggle('sticked', menuTopPosition == 0)

	const menuElements = Array.from(menu.querySelectorAll('.navUl li'))
	activateVisibleMenuSection(menuGeometry.height, menuElements.reverse())
}

/** Выделить пункт меню, который ссылается на видимый на экране блок страницы
 * @param {number} menuHeight
 * @param {HTMLLIElement[]} menuElements
 */
function activateVisibleMenuSection(menuHeight, menuElements) {
	if (!menuElements.length)
		return

	const mobileMenu = menuElements[0].closest('.landing2-inner-navigation').querySelector('select.custom-select')
	menuElements.forEach(el => el.classList.remove('active'))
	if (mobileMenu)
		mobileMenu.value = '.'

	/** @type {HTMLLIElement} */
	let activeMenuElement = null

	menuElements.forEach(el => {
		const target = document.querySelector(el.dataset.target)
		if (!target)
			return false
		const targetGeometry = target.getBoundingClientRect()

		const posTop = targetGeometry.top
		const posBottom = targetGeometry.bottom

		// Если элемент на экране хотя бы частично, считаем его видимым
		if (posTop < menuHeight && posBottom > menuHeight || posTop > menuHeight && posTop < window.innerHeight)
			activeMenuElement = el
	})

	if (activeMenuElement) {
		activeMenuElement.classList.add('active')

		if (mobileMenu)
			mobileMenu.value = activeMenuElement.dataset.target
	}

	if (uxSel)
		uxSel.update(false)
}